export default class DelayLoad {
  constructor() {
    this.action = ['keydown', 'mousedown', 'mousemove', 'touchmove', 'touchstart', 'touchend', 'wheel']
    this.callDelayB = false
  }

  delayCss() {
    $('.lazy-event').removeClass('lazy-event')
    $('[data-lazycss="true"]').each((i, e) => {
      $(e).replaceWith(`<link rel="stylesheet" id="${$(e).attr('id')}"  href="${$(e).data('href')}" media="screen">`)
    })
    if (typeof window.lazyLoadBg !== 'undefined') {
      window.lazyLoadBg.init()
    }
  }

  delayJs() {
    const $delayBeforeJs = $('[data-lazyjs="true"]')
    const scripts = Array.from($delayBeforeJs)
    let currentIndex = 0

    const loadScript = (scriptElement) => new Promise((resolve, reject) => {
      const newScript = document.createElement('script')
      const scriptUrl = $(scriptElement).data('src')

      Array.from(scriptElement.attributes).forEach((attr) => {
        if (!['data-src', 'data-lazyjs'].includes(attr.name)) {
          newScript.setAttribute(attr.name, attr.value)
        }
      })

      newScript.src = scriptUrl

      newScript.onload = () => resolve()
      newScript.onerror = () => {
        console.error(`Failed to load script: ${scriptUrl}`)
        resolve()
      }
      scriptElement.parentNode.replaceChild(newScript, scriptElement)
    })

    const loadNextScript = async () => {
      if (currentIndex < scripts.length) {
        await loadScript(scripts[currentIndex])
        currentIndex++

        if (currentIndex === scripts.length) {
          this.waitForRecaptcha()
        } else {
          setTimeout(loadNextScript, 100)
        }
      }
    }

    if (scripts.length > 0) {
      loadNextScript()
    }
  }

  loadDelayAfterScripts($delayAfter) {
    const scripts = Array.from($delayAfter)
    let currentIndex = 0

    const loadScript = (scriptElement) => new Promise((resolve, reject) => {
      const newScript = document.createElement('script')
      const scriptUrl = $(scriptElement).data('src')

      Array.from(scriptElement.attributes).forEach((attr) => {
        if (!['data-src', 'data-lazyjs', 'data-lazyjs-after'].includes(attr.name)) {
          newScript.setAttribute(attr.name, attr.value)
        }
      })

      newScript.src = scriptUrl

      newScript.onload = () => resolve()
      newScript.onerror = () => {
        console.error(`Failed to load script: ${scriptUrl}`)
        resolve()
      }

      scriptElement.parentNode.replaceChild(newScript, scriptElement)
    })

    const loadNextScript = async () => {
      if (currentIndex < scripts.length) {
        await loadScript(scripts[currentIndex])
        currentIndex++

        if (currentIndex < scripts.length) {
          setTimeout(loadNextScript, 100)
        }
      }
    }

    if (scripts.length > 0) {
      loadNextScript()
    }
  }

  waitForRecaptcha() {
    if (typeof grecaptcha !== 'undefined') {
      const $delayRecaptchaAfter = $('[data-recaptcha-after="true"]')
      const delayAfterJsLength = $delayRecaptchaAfter.length
      if (delayAfterJsLength > 0) {
        this.loadDelayAfterScripts($delayRecaptchaAfter)
      }
    } else {
      setTimeout(() => {
        this.waitForRecaptcha()
      }, 1000)
    }
  }

  callDelay() {
    if (this.callDelayB) return
    this.callDelayB = true
    this.delayCss()
    setTimeout(() => {
      this.delayJs()
    }, 100)
    if (typeof window.callMikmak !== 'undefined') {
      window.callMikmak()
    }
  }

  addEventListeners() {
    this.action.forEach((ac) => {
      document.addEventListener(ac, () => {
        this.callDelay()
      })
    })
  }

  init() {
    this.addEventListeners()
    if ($(window).scrollTop() > 0) {
      this.callDelay()
    }
  }
}

// Usage
const delayLoad = new DelayLoad()
delayLoad.init()
